import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TestiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getAllTesti() {
        const url = 'https://www.sandracatalano.it/wp-json/wp/v2/delwebedintorni';
        return this.httpClient.get(url);
    }
    getAllTestiFede() {
        // API per Portale Fede Ghost (Tutti i posts) : https://federicorinaldi.com/ghost/api/v3/content/posts/?key=c032a52abe093a9c10372caca8
        //const url = 'https://federicorinaldi.com/be/wp-json/wp/v2/articoli';
        const url = 'https://federicorinaldi.com/ghost/api/v3/content/posts/?key=7a02128e59f6c098207f1c4151&include=tags';
        return this.httpClient.get(url);
    }
}
TestiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TestiService_Factory() { return new TestiService(i0.ɵɵinject(i1.HttpClient)); }, token: TestiService, providedIn: "root" });
