import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
  })
export class YoutubeService {

    constructor(public httpClient:HttpClient){}
    private API_KEY = environment.google_api_key;
    private CHANNEL_ID = environment.channelId;

    getVideosFromChannel(maxResults):Observable<any> {
      let url = 'https://www.googleapis.com/youtube/v3/search?key=' + this.API_KEY + '&channelId=' + this.CHANNEL_ID + '&order=date&part=snippet &type=video,id&maxResults=' + maxResults;
      return this.httpClient.get<any>(url);
    }

    getVideosFromInvidiousApi(maxResults):Observable<any> {
      const url = environment.url_invidious + 'channels/'+this.CHANNEL_ID
      return this.httpClient.get<any>(url);
    }


}