<app-header></app-header>

<div id="panel" class="container-fluid mt-4">
    <div class="row m-4">
        <div class="col-md-12">
                <div id="carouselExampleCaptions" class="carousel slide carousel-fade carousel-dimensions" data-ride="carousel" >
                    <ol class="carousel-indicators">
                      <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                      <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                      <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                    </ol>
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img src="assets/carousel2.png" class="img-fluid text-center" alt="...">
                        <div class="carousel-caption d-none d-md-block">
                          <!-- <h5>First slide label</h5>
                          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> -->
                        </div>
                      </div>
                      <div class="carousel-item">
                        <img src="assets/carousel.png" class="img-fluid text-center" alt="...">
                        <div class="carousel-caption d-none d-md-block">
                          <!-- <h5>Second slide label</h5>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
                        </div>
                      </div>
<!--https://via.placeholder.com/728x190-->
                      <div class="carousel-item">
                        <img src="assets/carousel1.png" class="img-fluid text-center" alt="...">
                        <div class="carousel-caption d-none d-md-block">
                          <!-- <h5>Third slide label</h5>
                          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> -->
                        </div>
                      </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>

        </div>

    </div>
    <div class="row">
      <div class="callout-block text-center fade-in-b w-100">
        <h1>Vieni a conoscerci e scopri tutte le nostre iniziative! </h1>
        <p><a href="javascript:void(0);" class="btn btn-danger" (click)="callToAction()">Chi Siamo</a></p>
      </div>
    </div>
    <div class="row mt-3" *ngIf="shared?.videoPodcast">
        <div class="col-xl-12">

          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link active" id="video-tab" data-toggle="tab" href="#video" role="tab" aria-controls="video" aria-selected="true">Video Recenti</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Podcast Recenti</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Contenuti Recenti</a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="video" role="tabpanel" aria-labelledby="video-tab">
              <app-videopodcast [datiIngresso]="mediaY"></app-videopodcast>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <app-videopodcast [datiIngresso]="media"></app-videopodcast>
            </div>
            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
              <app-videopodcast [datiIngresso]="mediaT" ></app-videopodcast>
            </div>
          </div>
        </div>
        <!-- <div class="col-xl-4">
         <app-rightbar></app-rightbar>
        </div> -->
    </div>
    <div class="row">
      <div class="col-xl-12">
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- <div class="row" *ngIf="shared?.allPodcast || shared?.allVideos || shared?.allTesti">
      <div class="col-xl-12">
        <app-liste></app-liste>
      </div>
    </div>
    <div class="row" *ngIf="shared?.about">
      <div class="col-xl-12">
        <app-about></app-about>
      </div>
    </div>
    <div class="row" *ngIf="shared?.dettaglio">
      <div class="col-xl-12">
        <app-dettagli *ngIf="shared?.dettaglioTesto === undefined"></app-dettagli>
        <router-outlet *ngIf="shared?.dettaglioTesto !== undefined"></router-outlet>
      </div>
    </div> -->

</div>
<app-footer></app-footer>
<ngx-spinner type="pacman"></ngx-spinner>
