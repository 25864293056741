import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class CanonicalService {
    constructor(dom) {
        this.dom = dom;
    }
    createCanonicalUrl(url) {
        let canURL = url == undefined ? this.dom.URL : url;
        let link = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', canURL);
    }
}
CanonicalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanonicalService_Factory() { return new CanonicalService(i0.ɵɵinject(i1.DOCUMENT)); }, token: CanonicalService, providedIn: "root" });
