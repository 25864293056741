import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() cosa:string;

  formRicerca:FormGroup;

  constructor(
    private shared:SharedService,
    private fb:FormBuilder
  ) {

    this.formRicerca = this.fb.group({
      'ricerca':['']
    });
  }

  ngOnInit() {
    this.formRicerca.get('ricerca').valueChanges.pipe(debounceTime(200)).subscribe(value=>{
      if(value !== null){
        if(this.cosa.trim() === 'V'){
          this.shared.videocast =  this.shared.videocast.filter(v => v.snippet.title.toLowerCase().indexOf(value.toLowerCase()) > -1);
        }else if(this.cosa.trim() === 'P'){
          this.shared.podcast.items = this.shared.podcast.items.filter(v=> v.title.toLowerCase().indexOf(value.toLowerCase()) > -1);
        }else if(this.cosa.trim() === 'T'){
          this.shared.testi = this.shared.testi.filter(v=> v.title.rendered.toLowerCase().indexOf(value.toLowerCase()) > -1);
        }
      }
    });
  }


  reset(){
    this.formRicerca.get('ricerca').setValue(null);
    if(this.cosa === 'V'){
      this.shared.videocast = [];
      this.shared.videoCastOriginale.map(elem=>{
        this.shared.videocast.push(Object.assign({},elem));
      });
    }else if(this.cosa === 'P'){
      this.shared.podcast = Object.assign({},this.shared.podcastOriginale);
    } else if(this.cosa === 'T'){
      this.shared.testi = this.shared.testiOriginale;
    }
  }

}
