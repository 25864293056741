<div class="row">
    <div class="col-xl-12">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="" (click)="naviga('H')">Home</a></li>
              <li class="breadcrumb-item" *ngIf="checkMediaType(shared?.tipoListaShow) === 'P'" aria-current="page"><a [routerLink]="['recenti']">Lista Podcast</a></li>
              <li class="breadcrumb-item" *ngIf="checkMediaType(shared?.tipoListaShow) === 'Y'" aria-current="page"><a [routerLink]="['recenti']">Lista Video Lezioni</a></li>
              <li class="breadcrumb-item" *ngIf="checkMediaType(shared?.tipoListaShow) === 'T'" aria-current="page"><a [routerLink]="['recenti']">Lista Contenuti</a></li>
            </ol>
          </nav>
    </div>
</div>
<div class="row">
    <div class="col-xl-12">
        <h3 *ngIf="checkMediaType(shared?.tipoListaShow) === 'P'" class="text-center" style="font-family: 'Itim', cursive;">Podcast</h3>
        <h3 *ngIf="checkMediaType(shared?.tipoListaShow) === 'Y'" class="text-center" style="font-family: 'Itim', cursive;">Video Lezioni</h3>
        <h3 *ngIf="checkMediaType(shared?.tipoListaShow) === 'T'" class="text-center" style="font-family: 'Itim', cursive;">Contenuti</h3>
    </div>
</div>
<div class="row" *ngIf="checkMediaType(shared?.tipoListaShow) === 'Y'">
    <div class="col-xl-12">
        <app-search [cosa]="'V'"></app-search>
        <div class="card mt-3" *ngFor="let vid of shared?.videocast | slice: (page-1) * pageSize : page * pageSize">
            <div class="card-horizontal">
                <img [src]="vid.videoThumbnails[0].url" class="img-fluid" style="width: 30%; height: 30%;" alt="vid.title">
                <div class="card-body">
                    <h5 class="card-title" [innerHTML]="vid.title"></h5>
                    <p class="card-text" [innerHTML]="vid.descriptionHtml"></p>
                    <p>Pubblicato: {{vid.publishedText}}</p>
                  </div>
            </div>
            <div class="card-action text-right">
                <a [routerLink]="" class="btn btn-primary mb-2 mr-2" (click)="dettaglio(vid,'V')"><fa-icon [icon]="['fab', 'youtube']"></fa-icon> Vedi</a>
              </div>
          </div>
    </div>
    <div class="col-xl-12 text-center mt-4">
        <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="shared?.videocast?.length" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
    </div>
</div>

<div class="row" *ngIf="checkMediaType(shared?.tipoListaShow) === 'P'">
    <div class="col-xl-12 text-center">
        <app-search [cosa]="'P'"></app-search>
        <div class="card mt-3" *ngFor="let pod of shared?.podcast?.items | slice: (page-1) * pageSize : page * pageSize">
            <div class="card-horizontal">
                <img [src]="pod.thumbnail" class="img-fluid" style="width: 30%; height: 30%;" alt="pod.title">
                <div class="card-body">
                    <h5 class="card-title" [innerHTML]="pod.title"></h5>
                    <p class="card-text text-justify" [innerHTML]="pod.description | slice:0:400">...</p>
                  </div>

            </div>
            <div class="card-action text-right">
                <a [routerLink]="" class="btn btn-primary mb-2 mr-2" (click)="dettaglio(pod,'P')"><fa-icon [icon]="iconaAscolta"></fa-icon> Ascolta</a>
              </div>
          </div>
    </div>
    <div class="col-xl-12 text-center mt-4">
        <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="shared?.podcast?.items?.length" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
    </div>
</div>

<div class="row" *ngIf="checkMediaType(shared?.tipoListaShow) === 'T'">
    <div class="col-xl-12 text-center">
        <app-search [cosa]="'T'"></app-search>
        <div class="card mt-3" *ngFor="let testo of shared?.testi | slice: (page-1) * pageSize : page * pageSize">
            <div class="card-horizontal">
                <!-- <img src="assets/write.jpeg" class="img-fluid" style="width: 30%; height: 30%;"> -->
                <div class="card-body">
                    <h5 class="card-title" [innerHTML]="testo.title.rendered"></h5>
                    <p class="card-text text-justify" [innerHTML]="testo.excerpt.rendered | slice:0:400">...</p>
                  </div>
                  
            </div>
            <div class="card-action text-right mt-5">
                <a [routerLink]="" class="btn btn-primary mb-2 mr-2" (click)="dettaglio(testo,'T')"><fa-icon [icon]="iconaLeggi"></fa-icon> Leggi</a>
            </div>
          </div>
    </div>
    <div class="col-xl-12 text-center mt-4">
        <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="shared?.testi?.length" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
    </div>
</div>
