<div class="row" *ngIf="checkMediaType(datiIngresso?.type) === 'Y'">
  <ul class="list-group list-group-horizontal align-items-stretch flex-wrap justify-content-center">
    <li class="list-group-item" *ngFor="let vid of sharedService?.videocast | slice:0:8; ">
      <div class="card h-100" style="width: 16rem;" >
        <img [src]="vid.videoThumbnails[0].url" class="card-img-top" alt="vid.title">
        <div class="card-body">
          <h5 class="card-title" [innerHTML]="vid.title"></h5>
          <p class="card-text" [innerHTML]="vid.descriptionHtml"></p>
        </div>
        <div class="card-action text-right">
          <a [routerLink]="" class="btn btn-primary mb-2 mr-2" (click)="dettaglio(vid,'V')"> <fa-icon [icon]="['fab', 'youtube']"></fa-icon> Vedi</a>
        </div>
      </div>

    </li>
  </ul>
    <!-- <div class="col-sm-3 m-4" *ngFor="let vid of sharedService?.videocast | slice:0:6; ">
        <div class="card h-100" style="width: 16rem;" >
            <img [src]="vid.snippet.thumbnails.default.url" class="card-img-top" alt="vid.snippet.title">
            <div class="card-body">
              <h5 class="card-title">{{vid.snippet.title}}</h5>
              <p class="card-text">{{vid.snippet.description}}</p>
            </div>
            <div class="card-action text-right">
              <a href="#" class="btn btn-primary mb-2 mr-2" (click)="dettaglio(vid,'V')"> <fa-icon [icon]="['fab', 'youtube']"></fa-icon> Vedi</a>
            </div>
          </div>
    </div> -->
    <div class="col-xl-12 m-3" *ngIf="listaVideoCanale?.length === 0">
      <h3 class="text-center w-100" style="font-family: 'Itim', cursive;">Non sono stati trovati video</h3>
  </div>

</div>
<div class="row" *ngIf="checkMediaType(datiIngresso?.type) === 'P'">

    <ul class="list-group list-group-horizontal align-items-stretch flex-wrap">
      <li class="list-group-item" *ngFor="let pod of sharedService?.podcast?.items | slice:0:6; ">
        <div class="card h-100" style="width: 16rem;" >
          <img [src]="pod.thumbnail" class="card-img-top" alt="pod.title">
          <div class="card-body">
            <h5 class="card-title" [innerHTML]="pod.title"></h5>
            <p class="card-text" [innerHTML]="pod.description | slice:0:80"></p>

          </div>
          <div class="card-action text-right">
            <a [routerLink]="" class="btn btn-primary mb-2 mr-2" (click)="dettaglio(pod,'P')"><fa-icon [icon]="iconaAscolta"></fa-icon> Ascolta</a>
          </div>

        </div>
      </li>
  </ul>
        <!-- <div class="col-4 col-sm-4 col-md-4 col-lg-4" *ngFor="let pod of sharedService?.podcast?.items | slice:0:6; ">
            <div class="card h-100" style="width: 16rem;" >
                <img [src]="pod.thumbnail" class="card-img-top" alt="pod.title">
                <div class="card-body">
                  <h5 class="card-title">{{pod.title}}</h5>
                  <p class="card-text">{{pod.description | slice:0:80}}</p>

                </div>
                <div class="card-action text-right">
                  <a href="#" class="btn btn-primary mb-2 mr-2" (click)="dettaglio(pod,'P')"><fa-icon [icon]="iconaAscolta"></fa-icon> Ascolta</a>
                </div>

              </div>
        </div> -->
        <div class="col-xl-12 m-3" *ngIf="podcast === undefined || podcast?.items?.length === 0">
          <h3 class="text-center w-100" style="font-family: 'Itim', cursive;">Non sono stati trovati podcast</h3>
      </div>
    </div>
    <div class="row" *ngIf="checkMediaType(datiIngresso?.type) === 'T'">
      <ul class="list-group list-group-horizontal align-items-stretch flex-wrap">
        <li class="list-group-item" *ngFor="let testo of sharedService?.testi | slice:0:6; ">
          <div class="card h-100" style="width: 16rem;" >
            <img src="assets/write.jpeg" class="card-img-top" alt="{{testo.title.rendered}}">
            <div class="card-body">
              <h5 class="card-title" [innerHTML]="testo.title.rendered"></h5>
              <p class="card-text" [innerHTML]="testo.excerpt.rendered.substring(0,100) + '...'"></p>
  
            </div>
            <div class="card-action text-right">
              <a [routerLink]="" class="btn btn-primary mb-2 mr-2" (click)="dettaglio(testo,'T')"><fa-icon [icon]="iconaLeggi"></fa-icon> Leggi</a>
            </div>
  
          </div>
        </li>
    </ul>



      <div class="col-xl-12 m-3" *ngIf="sharedService?.testi === undefined || sharedService?.testi?.length === 0">
        <h3 class="text-center w-100" style="font-family: 'Itim', cursive;">Non sono stati trovati contenuti</h3>
    </div>
    </div>
    <!-- <div class="col-xl-3 m-3">
        <div class="card" style="width: 18rem;">
            <img src="https://via.placeholder.com/150x150" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
          </div>
    </div>
    <div class="col-xl-3 m-3">
        <div class="card" style="width: 18rem;">
            <img src="https://via.placeholder.com/150x150" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
          </div>
    </div>
    <div class="col-xl-3 m-3">
        <div class="card" style="width: 18rem;">
            <img src="https://via.placeholder.com/150x150" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
          </div>
    </div> -->
