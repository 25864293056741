<div class="row m-4">
    <div class="col-md-12">
        <div class="media">
          <!-- <img src="assets/ic_launcher.png" class="align-self-start mr-3" alt="..." width="64" height="64"> -->
          <div class="media-body">
            <h5 class="mt-0">Benvenuti</h5>
            <p class="text-justify">
              Siamo uniti da una grande amicizia che dura da tempi della scuola quando, io docente giovane e lui alunno quasi coetaneo, abbiamo cominciato a condividere interessi e passioni comuni. Abbiamo lavorato e lavoriamo da anni nell'ambito dell'informatica con competenze diverse che ben si sono integrate e che hanno portato ad un ottimo livello di conoscenza di molte delle aree riconducibili a questa tematica.
            </p>
            <p class="text-justify">
              Siamo sperimentatori e sviluppatori di idee, applicativi, software, siti ecc.. e anche  musica perché la nostra passione è la conoscenza a tutto tondo.
            </p>
            <p class="text-justify">
              In questo momento specifico, con l'emergenza CORONAVIRUS, ci siamo accorti che le nostre competenze posso essere di aiuto a tutti coloro che si trovano a dover implementare, ripensare, modificare il loro modo di insegnare nelle scuole.
            </p>
            <p class="text-justify">
              Per questo ci stiamo mettendo a disposizione per quanti abbiano necessità di muoversi in questo mondo.
            </p>
            <p class="text-left">
                Grazie e buona visione. 
            </p>
            <p class="text-left font-weight-bold">
                Sandra Catalano e Federico Rinaldi
            </p>
          </div>
        </div>
      </div>

</div>