import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MediaType } from './enums/mediatype.enum';
import { MediaModel } from './models/media.model';
import { CanonicalService } from './services/canonical.service';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  media:MediaModel = {} as MediaModel;
  mediaY:MediaModel = {} as MediaModel;
  mediaT:MediaModel = {} as MediaModel;

  constructor(
    public shared:SharedService,
    private route:Router,
    private metaTag:Meta,
    private canonical:CanonicalService,
    private datePipe:DatePipe,
    private titleMeta:Title,
    ){
    
  }
  title = 'aggregatore';

  ngOnInit(){

    this.mediaY.type = MediaType.YOUTUBE;
    this.media.type = MediaType.PODCAST;
    this.mediaT.type = MediaType.TESTI;
    this.shared.resetAllComponents();
    this.shared.videoPodcast = true;

    this.metaTag.removeTag('name="description"');
    this.metaTag.removeTag('name="date"');
    this.metaTag.removeTag('name="authors"');
    this.metaTag.removeTag('name="keywords"');
    this.metaTag.removeTag('name="robots"');
     this.metaTag.addTags([
       {name : 'description', content : `Home Page Del Web e Dintorni - MaiStatoCosiFacile`},
       {name: 'date', content : `${this.formattaData(new Date())}`},
       {name : 'authors', content : 'Federico Rinaldi & Sandra Catalano'},
       {name : 'keywords', content : 'Informatica, Sviluppo web, java, angular, scuola, DAD, Google Meet, Google Learning, Coronavirus, Covid, Covid19, Scuola'},
       {name : 'robots', content : 'index, follow'}
     ]);
     this.titleMeta.setTitle(`Del Web e Dintorni MaiStatoCosiFacile`);
     this.canonical.createCanonicalUrl(`/`);

  }


  formattaData(dataPost:Date) : string | null{
    return this.datePipe.transform(dataPost,'dd/MM/yyyy','it-IT');
  }


  callToAction(){
    this.shared.resetAllComponents();
    this.shared.about = true;
    this.route.navigate(['about']);
  }
}
