import { timer } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "ngx-spinner";
export class SharedService {
    constructor(spinner) {
        this.spinner = spinner;
        this.videoCastOriginale = [];
        this.podcastOriginale = [];
        this.testiOriginale = [];
    }
    resetAllComponents() {
        this.videoPodcast = false;
        this.allPodcast = false;
        this.allVideos = false;
        this.allTesti = false;
        this.dettaglio = false;
        this.about = false;
        this.provenienza = null;
        this.dettaglioPod = null;
        this.dettaglioTesto = null;
        this.dettaglioVid = null;
    }
    loadAndHideSpinner() {
        timer(400).subscribe(() => {
            this.spinner.show();
        });
        timer(3000).subscribe(() => {
            this.spinner.hide();
        });
    }
    createBlogFromGhostPublishing(lista) {
        const listaWpBlog = [];
        lista.posts.forEach((post) => {
            if (post.tags[0] && post.tags[0].name === 'maistatocosifacile') {
                const wpPost = {};
                wpPost.id = post.id;
                wpPost.immagine = post.feature_image;
                wpPost.title = {};
                wpPost.title.rendered = post.title;
                wpPost.excerpt = {};
                wpPost.excerpt.rendered = post.excerpt;
                wpPost.date = post.published_at;
                wpPost.modified = post.updated_at;
                wpPost.link = post.url;
                wpPost.content = {};
                wpPost.content.rendered = post.html;
                listaWpBlog.push(wpPost);
            }
        });
        return listaWpBlog;
    }
    convertTimestampToDate(timestamp) {
        const published = new Date(timestamp);
        console.log(published.toLocaleDateString('it-it'));
        return published;
    }
}
SharedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedService_Factory() { return new SharedService(i0.ɵɵinject(i1.NgxSpinnerService)); }, token: SharedService, providedIn: "root" });
