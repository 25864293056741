import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { faImage,faSearch} from '@fortawesome/free-solid-svg-icons';
import { merge, Observable, Subject } from 'rxjs';
import { MediaType } from '../enums/mediatype.enum';
import { SharedService } from '../services/shared.service';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgxNavbarCollapseComponent } from 'ngx-bootstrap-navbar';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';
import { DatePipe } from '@angular/common';
import { MediaModel } from '../models/media.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  insta = faImage;
  ricerca = faSearch;
  public model: any;
  focus$ = new Subject<any>();
  click$ = new Subject<any>();

  media:MediaModel = {} as MediaModel;
  mediaY:MediaModel = {} as MediaModel;
  mediaT:MediaModel = {} as MediaModel;

  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  @ViewChild('collapse',{static: true}) collapse:NgxNavbarCollapseComponent;
  constructor(
    private shared:SharedService,
    private route:Router,
    private metaTag:Meta,
    private canonical:CanonicalService,
    private datePipe:DatePipe,
    private titleMeta:Title,
  ) { }

  ngOnInit() {
    this.click$.subscribe(res=>{
      console.log(res.snippet !== undefined ? res.snippet.description : 'vuoto');
    });
  }


  selezionaListe(cosa:string){
    this.shared.loadAndHideSpinner();
    this.shared.resetAllComponents();
    this.collapse.close();
    switch (cosa) {
        case 'V':
          this.shared.tipoListaShow = MediaType.YOUTUBE;
          this.shared.allVideos = true;
          this.route.navigate(['recenti']);
          break;
        case 'P' :
          this.shared.tipoListaShow = MediaType.PODCAST;
          this.shared.allPodcast = true;
          this.route.navigate(['recenti']);
          break;
        case 'T':
          this.shared.tipoListaShow = MediaType.TESTI;
          this.shared.allTesti = true;
          this.route.navigate(['recenti']);
          break;
        case 'C':
          this.shared.about = true;
          this.route.navigate(['about']);
        break;
    }
  }


  // search = (text$: Observable<any>) =>
  //   text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged(),
  //     map(term => term.length < 2 ? []
  //       : this.shared.videocast.filter(v => v.snippet.title.toLowerCase().indexOf(term.toLowerCase()) > -1))
  //   )

    search = (text$: Observable<any>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
      const inputFocus$ = this.focus$;

      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        map(term => (term === '' ? this.shared.videocast
          : this.shared.videocast.filter(v => v.snippet.title.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
      );
    }

   formatterView = (x: {snippet: any}) => x.snippet.title;

   formatter = (x: {snippet: any}) => x.snippet;

  home(){
    this.shared.resetAllComponents();
    this.shared.videoPodcast = true;
    this.resetMetaContent();
    this.route.navigateByUrl(
      `/`);
  }


  resetMetaContent(){
    this.mediaY.type = MediaType.YOUTUBE;
    this.media.type = MediaType.PODCAST;
    this.mediaT.type = MediaType.TESTI;
    this.shared.resetAllComponents();
    this.shared.videoPodcast = true;

    this.metaTag.removeTag('name="description"');
    this.metaTag.removeTag('name="date"');
    this.metaTag.removeTag('name="authors"');
    this.metaTag.removeTag('name="keywords"');
    this.metaTag.removeTag('name="robots"');
     this.metaTag.addTags([
       {name : 'description', content : `Home Page Del Web e Dintorni - MaiStatoCosiFacile`},
       {name: 'date', content : `${this.formattaData(new Date())}`},
       {name : 'authors', content : 'Federico Rinaldi & Sandra Catalano'},
       {name : 'keywords', content : 'Informatica, Sviluppo web, java, angular, scuola, DAD, Google Meet, Google Learning, Coronavirus, Covid, Covid19, Scuola'},
       {name : 'robots', content : 'index, follow'}
     ]);
     this.titleMeta.setTitle(`Del Web e Dintorni MaiStatoCosiFacile`);
     this.canonical.createCanonicalUrl(`/`);
  }

  formattaData(dataPost:Date) : string | null{
    return this.datePipe.transform(dataPost,'dd/MM/yyyy','it-IT');
  }

}
