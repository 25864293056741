<div class="row">
    <div class="col-md-12">
        <form [formGroup]="formRicerca">
            <div class="form-row align-items-center w-100 mt-2">
                <div class="col-auto">
                    <input type="email" class="form-control" formControlName="ricerca" id="ricerca" aria-describedby="ricercaHelp" placeholder="Cerca..." >
                </div>
                <div class="col-auto">
                    <button class="btn btn-outline-success my-2 my-sm-0" id="reset" name="reset" type="button" (click)="reset()">RESET</button>
                </div>
            </div>
        </form>
    </div>
</div>
