import { CanonicalService } from './../services/canonical.service';
import { Component, OnInit } from '@angular/core';
import { MediaType } from '../enums/mediatype.enum';
import { SharedService } from '../services/shared.service';
import { faHeadphonesAlt, faBook} from '@fortawesome/free-solid-svg-icons';
import { WPblogModel } from '../models/wp-blog.model';
import { forkJoin, Observable } from 'rxjs';
import { TestiService } from '../services/testi.service';
import { Router } from '@angular/router';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { WPTitleModel } from '../models/wp-title.model';
import { WpExcerptModel } from '../models/wp-excerpt.model';
import { WPContentModel } from '../models/wp-content.model';
@Component({
  selector: 'app-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss']
})
export class ListeComponent implements OnInit {

  public page:number = 1;
  public pageSize:number = 5;
  iconaAscolta = faHeadphonesAlt;
  iconaLeggi = faBook;
  constructor(
    public shared:SharedService,
    public testi:TestiService,
    private route:Router,
    private sanitizer: DomSanitizer,
    private metaTag:Meta,
    private canonical:CanonicalService,
    private datePipe:DatePipe,
    private titleMeta:Title,
  ) { }

  ngOnInit() {
    if(this.shared.testi === undefined || this.shared.testi.length === 0){
      this.getAllTestiFromSandra();
    }
    if(this.shared.tipoListaShow === undefined){
      this.route.navigate(['']);
    }
    this.metaTag.removeTag('name="description"');
    this.metaTag.removeTag('name="date"');
    this.metaTag.removeTag('name="authors"');
    this.metaTag.removeTag('name="keywords"');
    this.metaTag.removeTag('name="robots"');
     this.metaTag.addTags([
       {name : 'description', content : `Home Page Del Web e Dintorni - MaiStatoCosiFacile`},
       {name: 'date', content : `${this.formattaData(new Date())}`},
       {name : 'authors', content : 'Federico Rinaldi & Sandra Catalano'},
       {name : 'keywords', content : 'Informatica, Sviluppo web, java, angular, scuola, DAD, Google Meet, Google Learning, Coronavirus, Covid, Covid19, Scuola'},
       {name : 'robots', content : 'index, follow'}
     ]);
     this.titleMeta.setTitle(`Del Web e Dintorni MaiStatoCosiFacile - Contenuti Recenti`);
     this.canonical.createCanonicalUrl(`/recenti`);

  }
  formattaData(dataPost:Date) : string | null{
    return this.datePipe.transform(dataPost,'dd/MM/yyyy','it-IT');
  }

  checkMediaType(type:MediaType){
    if(type === MediaType.YOUTUBE){
      return 'Y';
    }else if(type === MediaType.PODCAST){
      return 'P';
    }else if(type === MediaType.TESTI){
      return 'T';
    }
  }

  dettaglio(obj:any,cosa:string){
    this.shared.loadAndHideSpinner();
    if(cosa === 'P'){
      this.shared.resetAllComponents();
      this.shared.provenienza = 'L';
      this.shared.dettaglio = true;
      this.shared.dettaglioPod = obj;
      this.route.navigate(['dettaglio/podcast'])
    }else if(cosa === 'V'){
      this.shared.resetAllComponents();
      this.shared.provenienza = 'L';
      this.shared.dettaglio = true;
      this.shared.dettaglioVid = obj;
      this.route.navigate(['dettaglio/video'])
    }else if(cosa === 'T'){
      this.shared.resetAllComponents();
      this.shared.provenienza = 'L';
      this.shared.dettaglio = true;
      this.shared.dettaglioTesto = obj as WPblogModel;
      let titolo:string = this.shared.dettaglioTesto.title.rendered.trim().replace(/\s/g, '_');
      this.route.navigateByUrl(`/articolo/${this.shared.dettaglioTesto.id}/${titolo}`);
    }
  }

  naviga(dove:string){
    if(dove === 'H'){
      this.shared.resetAllComponents();
      this.shared.videoPodcast = true;
      this.route.navigate(['']);
    }
  }


  private getAllTestiFromSandra(){
    const postSandra:Observable<WPblogModel[]> = this.testi.getAllTesti();
    const postFede:Observable<any> = this.testi.getAllTestiFede();
    forkJoin(postSandra,postFede).subscribe(result=>{
      const listaFede:WPblogModel[] = this.shared.createBlogFromGhostPublishing(result[1]);
      this.shared.testi = [...result[0],...listaFede];
      this.shared.testiOriginale = [...result[0],...listaFede];
      this.shared.testi.sort((a,b)=>a.date > b.date ? -1 : a.date < b.date ? 1 : 0);
    });
  }




}
