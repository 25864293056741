<nav ngxNavbarDynamicExpand class="navbar-light bg-light fixed-top">
    <a class="navbar-brand" style="font-family: 'Itim', cursive;" [routerLink]="" (click)="home()"><img src="assets/Logo3.png"/></a>

    <button
      class="navbar-toggler"
      type="button"
      (click)="collapse.toggle()"
      aria-controls="main-nav"
      aria-expanded="false"
      aria-label="Navigáció kapcsoló"
    >
      <span
        class="navbar-toggler-icon d-flex align-items-center justify-content-left"
      >
      </span>
    </button>
    <ngx-navbar-collapse id="main-nav" #collapse="ngxNavbarCollapse" >
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <a class="nav-link" [routerLink]="" (click)="selezionaListe('V')">Video </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="" (click)="selezionaListe('P')">Podcast</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="" (click)="selezionaListe('T')">Contenuti</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="" (click)="selezionaListe('C')">Chi Siamo</a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" title="Seguici su Instagram">
            <a class="nav-link"  href="https://instagram.com/maistatocosifacile" target="_blank"
              ><fa-icon [icon]="['fab', 'instagram']"></fa-icon>   <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" title="Seguici su Facebook">
          <a class="nav-link" href="https://wwww.facebook.com/groups/didatticaintegratabysandracatalano/" target="_blank"><fa-icon [icon]="['fab', 'facebook']"></fa-icon>  </a>
          </li>
          <li class="nav-item" title="Seguici su Twitter">
            <a class="nav-link" href="https://twitter.com/sandracatalano?s=21" target="_blank"><fa-icon [icon]="['fab', 'twitter']"></fa-icon>  </a>
          </li>
          <!-- <li class="nav-item" title="Fai una ricerca">
            <form class="form-inline">
              <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" [ngbTypeahead]="search"
               [resultFormatter]="formatterView" [inputFormatter]="formatter" [(ngModel)]="model"
               (focus)="focus$.next($any($event).target.value)"
               (click)="click$.next($any($event).target.value)"
               name="model" #instance="ngbTypeahead">
              <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
            </form>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <fa-icon [icon]="ricerca"></fa-icon>
                </span>
              </div>
              <input type="text" class="form-control" placeholder="Cerca..." aria-label="Cerca..." aria-describedby="basic-addon1">
            </div>
          </li> -->
      </ul>
    </ngx-navbar-collapse>
  </nav>
