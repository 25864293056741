import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PodCastService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getListOfPodcast() {
        const url = 'https://api.rss2json.com/v1/api.json?rss_url=https://anchor.fm/s/1139e084/podcast/rss';
        return this.httpClient.get(url);
    }
}
PodCastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PodCastService_Factory() { return new PodCastService(i0.ɵɵinject(i1.HttpClient)); }, token: PodCastService, providedIn: "root" });
