import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { AppComponent } from './app.component';
import { ArticoloComponent } from './articolo/articolo/articolo.component';
import { DettagliComponent } from './dettagli/dettagli.component';
import { HomeComponent } from './home/home.component';
import { ListeComponent } from './liste/liste.component';


const routes: Routes = [

  {
    path:'articolo/:id/:title',
    component:ArticoloComponent
  },
  {
    path:'about',
    component:AboutComponent
  },
  {
    path:'recenti',
    component:ListeComponent,
  },
  {
    path:'dettaglio/:cosa',
    component:DettagliComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
