/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./videopodcast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./videopodcast.component";
import * as i8 from "../services/youtube.service";
import * as i9 from "../services/podcast.service";
import * as i10 from "../services/shared.service";
import * as i11 from "ngx-spinner";
import * as i12 from "../services/testi.service";
var styles_VideopodcastComponent = [i0.styles];
var RenderType_VideopodcastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideopodcastComponent, data: {} });
export { RenderType_VideopodcastComponent as RenderType_VideopodcastComponent };
function View_VideopodcastComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "card h-100"], ["style", "width: 16rem;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "vid.title"], ["class", "card-img-top"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h5", [["class", "card-title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "p", [["class", "card-text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "card-action text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "a", [["class", "btn btn-primary mb-2 mr-2"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.dettaglio(_v.context.$implicit, "V") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(10, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(11, 2), (_l()(), i1.ɵted(-1, null, [" Vedi"]))], function (_ck, _v) { var currVal_7 = _ck(_v, 11, 0, "fab", "youtube"); _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.videoThumbnails[0].url; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.descriptionHtml; _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 8).target; var currVal_4 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 10).title; var currVal_6 = i1.ɵnov(_v, 10).renderedIconHTML; _ck(_v, 9, 0, currVal_5, currVal_6); }); }
function View_VideopodcastComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-xl-12 m-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "text-center w-100"], ["style", "font-family: 'Itim', cursive;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Non sono stati trovati video"]))], null, null); }
function View_VideopodcastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ul", [["class", "list-group list-group-horizontal align-items-stretch flex-wrap justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VideopodcastComponent_2)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideopodcastComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(((_co.sharedService == null) ? null : _co.sharedService.videocast), 0, 8)); _ck(_v, 3, 0, currVal_0); var currVal_1 = (((_co.listaVideoCanale == null) ? null : _co.listaVideoCanale.length) === 0); _ck(_v, 6, 0, currVal_1); }, null); }
function View_VideopodcastComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "card h-100"], ["style", "width: 16rem;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "pod.title"], ["class", "card-img-top"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h5", [["class", "card-title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "card-text"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i3.SlicePipe, []), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "card-action text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "a", [["class", "btn btn-primary mb-2 mr-2"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.dettaglio(_v.context.$implicit, "P") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(11, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵted(-1, null, [" Ascolta"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.iconaAscolta; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.thumbnail; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit.description, 0, 80)); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 9).target; var currVal_4 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 11).title; var currVal_6 = i1.ɵnov(_v, 11).renderedIconHTML; _ck(_v, 10, 0, currVal_5, currVal_6); }); }
function View_VideopodcastComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-xl-12 m-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "text-center w-100"], ["style", "font-family: 'Itim', cursive;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Non sono stati trovati podcast"]))], null, null); }
function View_VideopodcastComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ul", [["class", "list-group list-group-horizontal align-items-stretch flex-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VideopodcastComponent_5)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideopodcastComponent_6)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(((_co.sharedService == null) ? null : ((_co.sharedService.podcast == null) ? null : _co.sharedService.podcast.items)), 0, 6)); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.podcast === undefined) || (((_co.podcast == null) ? null : ((_co.podcast.items == null) ? null : _co.podcast.items.length)) === 0)); _ck(_v, 6, 0, currVal_1); }, null); }
function View_VideopodcastComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "card h-100"], ["style", "width: 16rem;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "card-img-top"], ["src", "assets/write.jpeg"]], [[8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h5", [["class", "card-title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "p", [["class", "card-text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "card-action text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "a", [["class", "btn btn-primary mb-2 mr-2"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.dettaglio(_v.context.$implicit, "T") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(10, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵted(-1, null, [" Leggi"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.iconaLeggi; _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.title.rendered, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.title.rendered; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.excerpt.rendered.substring(0, 100) + "..."); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 8).target; var currVal_4 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 10).title; var currVal_6 = i1.ɵnov(_v, 10).renderedIconHTML; _ck(_v, 9, 0, currVal_5, currVal_6); }); }
function View_VideopodcastComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-xl-12 m-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "text-center w-100"], ["style", "font-family: 'Itim', cursive;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Non sono stati trovati contenuti"]))], null, null); }
function View_VideopodcastComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ul", [["class", "list-group list-group-horizontal align-items-stretch flex-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VideopodcastComponent_8)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideopodcastComponent_9)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(((_co.sharedService == null) ? null : _co.sharedService.testi), 0, 6)); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((((_co.sharedService == null) ? null : _co.sharedService.testi) === undefined) || (((_co.sharedService == null) ? null : ((_co.sharedService.testi == null) ? null : _co.sharedService.testi.length)) === 0)); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_VideopodcastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideopodcastComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideopodcastComponent_4)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideopodcastComponent_7)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.checkMediaType(((_co.datiIngresso == null) ? null : _co.datiIngresso.type)) === "Y"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.checkMediaType(((_co.datiIngresso == null) ? null : _co.datiIngresso.type)) === "P"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.checkMediaType(((_co.datiIngresso == null) ? null : _co.datiIngresso.type)) === "T"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_VideopodcastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-videopodcast", [], null, null, null, View_VideopodcastComponent_0, RenderType_VideopodcastComponent)), i1.ɵdid(1, 114688, null, 0, i7.VideopodcastComponent, [i8.YoutubeService, i9.PodCastService, i10.SharedService, i11.NgxSpinnerService, i12.TestiService, i1.ChangeDetectorRef, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideopodcastComponentNgFactory = i1.ɵccf("app-videopodcast", i7.VideopodcastComponent, View_VideopodcastComponent_Host_0, { datiIngresso: "datiIngresso" }, {}, []);
export { VideopodcastComponentNgFactory as VideopodcastComponentNgFactory };
