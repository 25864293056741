import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class YoutubeService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.API_KEY = environment.google_api_key;
        this.CHANNEL_ID = environment.channelId;
    }
    getVideosFromChannel(maxResults) {
        let url = 'https://www.googleapis.com/youtube/v3/search?key=' + this.API_KEY + '&channelId=' + this.CHANNEL_ID + '&order=date&part=snippet &type=video,id&maxResults=' + maxResults;
        return this.httpClient.get(url);
    }
    getVideosFromInvidiousApi(maxResults) {
        const url = environment.url_invidious + 'channels/' + this.CHANNEL_ID;
        return this.httpClient.get(url);
    }
}
YoutubeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function YoutubeService_Factory() { return new YoutubeService(i0.ɵɵinject(i1.HttpClient)); }, token: YoutubeService, providedIn: "root" });
