import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    private metaTag:Meta,
    private canonical:CanonicalService,
    private datePipe:DatePipe,
    private titleMeta:Title,
  ) { }

  ngOnInit() {
    this.metaTag.removeTag('name="description"');
    this.metaTag.removeTag('name="date"');
    this.metaTag.removeTag('name="authors"');
    this.metaTag.removeTag('name="keywords"');
    this.metaTag.removeTag('name="robots"');
     this.metaTag.addTags([
       {name : 'description', content : `Home Page Del Web e Dintorni - MaiStatoCosiFacile Chi Siamo`},
       {name: 'date', content : `${this.formattaData(new Date())}`},
       {name : 'authors', content : 'Federico Rinaldi & Sandra Catalano'},
       {name : 'keywords', content : 'Informatica, Sviluppo web, java, angular, scuola, DAD, Google Meet, Google Learning, Coronavirus, Covid, Covid19, Scuola, About'},
       {name : 'robots', content : 'index, follow'}
     ]);
     this.titleMeta.setTitle(`Del Web e Dintorni MaiStatoCosiFacile - Chi Siamo`);
     this.canonical.createCanonicalUrl(`/about`);
  }

  formattaData(dataPost:Date) : string | null{
    return this.datePipe.transform(dataPost,'dd/MM/yyyy','it-IT');
  }

}
