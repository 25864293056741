import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WPblogModel } from '../models/wp-blog.model';

@Injectable({
  providedIn: 'root'
})
export class TestiService {

  constructor(private httpClient:HttpClient) { }

  getAllTesti():Observable<WPblogModel[]>{
    const url = 'https://www.sandracatalano.it/wp-json/wp/v2/delwebedintorni';
    return this.httpClient.get<WPblogModel[]>(url);
  }


  getAllTestiFede():Observable<any>{
    // API per Portale Fede Ghost (Tutti i posts) : https://federicorinaldi.com/ghost/api/v3/content/posts/?key=c032a52abe093a9c10372caca8
    //const url = 'https://federicorinaldi.com/be/wp-json/wp/v2/articoli';
    const url = 'https://federicorinaldi.com/ghost/api/v3/content/posts/?key=7a02128e59f6c098207f1c4151&include=tags';
    return this.httpClient.get<any>(url);
  }
}
