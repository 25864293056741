import { BrowserModule,Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { NgxNavbarModule } from 'ngx-bootstrap-navbar';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSquare, faCheckSquare,faSearch, faHeadphonesAlt} from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare, faCheckSquare as farCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faStackOverflow, faGithub, faMedium, faFacebook, faInstagram, faTwitter,faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VideopodcastComponent } from './videopodcast/videopodcast.component';
import { RightbarComponent } from './rightbar/rightbar.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { YoutubeService } from './services/youtube.service';
import { PodCastService } from './services/podcast.service';
import { SharedService } from './services/shared.service';
import { ListeComponent } from './liste/liste.component';
import {NgbPaginationModule,NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import { DettagliComponent } from './dettagli/dettagli.component';
import { SearchComponent } from './search/search.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { AboutComponent } from './about/about.component';
import { TestiService } from './services/testi.service';
import { SanitizeHtmlPipe } from './pipes/sanitize.pipe';
import { ArticoloComponent } from './articolo/articolo/articolo.component';
import { CanonicalService } from './services/canonical.service';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    VideopodcastComponent,
    RightbarComponent,
    FooterComponent,
    ListeComponent,
    DettagliComponent,
    SearchComponent,
    AboutComponent,
    SanitizeHtmlPipe,
    ArticoloComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxNavbarModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ],
  providers: [YoutubeService,PodCastService,SharedService,TestiService,Meta,CanonicalService,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private library: FaIconLibrary) {
    library.addIcons(faSquare, faCheckSquare, farSquare
        , farCheckSquare, faStackOverflow, faGithub, faMedium, faFacebook,faInstagram,faTwitter,faSearch,faHeadphonesAlt,faYoutube);
  }
}
