import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
export class PodCastService {
    
    constructor(private httpClient:HttpClient){}

    getListOfPodcast():Observable<any>{
        const url = 'https://api.rss2json.com/v1/api.json?rss_url=https://anchor.fm/s/1139e084/podcast/rss';
        return this.httpClient.get(url);
    }
}