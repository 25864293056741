import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { timer } from 'rxjs';
import { MediaType } from '../enums/mediatype.enum';
import { WPblogModel } from '../models/wp-blog.model';
import { WPContentModel } from '../models/wp-content.model';
import { WpExcerptModel } from '../models/wp-excerpt.model';
import { WPTitleModel } from '../models/wp-title.model';


@Injectable({
    providedIn: 'root',
  })
export class SharedService {

    constructor(private spinner:NgxSpinnerService){

    }

    public podcast:any;
    public videocast:any[];
    public testi:WPblogModel[];

    videoCastOriginale:any[] = [];
    podcastOriginale:any[] = [];
    testiOriginale:WPblogModel[] = [];

    public dettaglioVid:any;
    public dettaglioPod:any;
    public dettaglioTesto:WPblogModel;

    public provenienza:string;

    //Componenti da attivare disattivare
    public videoPodcast:boolean;
    public allPodcast:boolean;
    public allVideos:boolean;
    public allTesti:boolean;
    public dettaglio:boolean;
    public about:boolean;
    //Componenti da attivare disattivare

    //tipoListe da visualizzare
    public tipoListaShow:MediaType;
    public tipoDettaglioShow:MediaType;


    resetAllComponents(){
        this.videoPodcast = false;
        this.allPodcast = false;
        this.allVideos = false;
        this.allTesti = false;
        this.dettaglio = false;
        this.about = false;
        this.provenienza = null;
        this.dettaglioPod = null;
        this.dettaglioTesto = null;
        this.dettaglioVid = null;
    }


    loadAndHideSpinner(){
      timer(400).subscribe(()=>{
        this.spinner.show();
      });

      timer(3000).subscribe(()=>{
        this.spinner.hide();
      });
    }


    createBlogFromGhostPublishing(lista:any):WPblogModel[]{
      const listaWpBlog:WPblogModel[] = [];
      lista.posts.forEach((post)=>{
        if(post.tags[0] && post.tags[0].name === 'maistatocosifacile'){
          const wpPost:WPblogModel = {} as WPblogModel;
          wpPost.id = post.id;
          wpPost.immagine = post.feature_image;
          wpPost.title = {} as WPTitleModel;
          wpPost.title.rendered = post.title;
          wpPost.excerpt = {} as WpExcerptModel;
          wpPost.excerpt.rendered = post.excerpt;
          wpPost.date = post.published_at;
          wpPost.modified = post.updated_at;
          wpPost.link = post.url;
          wpPost.content = {} as WPContentModel;
          wpPost.content.rendered = post.html;
    
          listaWpBlog.push(wpPost);
        }
      });
      return listaWpBlog;
     }

     convertTimestampToDate(timestamp:number):Date{
      const published =  new Date(timestamp);
      console.log(published.toLocaleDateString('it-it'));
      return published;
     }
}
