<div class="row">
  <div class="col-xl-12">
      <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="" (click)="naviga('H')">Home</a></li>
            <li class="breadcrumb-item" *ngIf="shared?.dettaglioPod && shared?.provenienza === 'L'" ><a [routerLink]="" (click)="naviga('LP')" >Lista Podcast</a></li>
            <li class="breadcrumb-item" *ngIf="shared?.dettaglioVid  && shared?.provenienza === 'L'" ><a [routerLink]="" (click)="naviga('LV')">Lista Video Lezioni</a></li>
            <li class="breadcrumb-item" *ngIf="shared?.dettaglioTesto  && shared?.provenienza === 'L'" ><a [routerLink]="" (click)="naviga('LT')">Lista Contenuti</a></li>

            <li class="breadcrumb-item active" *ngIf="shared?.dettaglioPod"  aria-current="page">Dettaglio Podcast</li>
            <li class="breadcrumb-item active" *ngIf="shared?.dettaglioVid"  aria-current="page">Dettaglio Video Lezione</li>
            <li class="breadcrumb-item active" *ngIf="shared?.dettaglioTesto"  aria-current="page">{{shared?.dettaglioTesto?.title.rendered}}</li>
          </ol>
        </nav>
  </div>
</div>
<div class="row" *ngIf="shared?.dettaglioTesto">
    <div class="col">
        <div class="card w-100"  >
            <div class="card-body">
              <!-- <div class="row text-center">
                <div class="col">
                  <img [src]="shared?.dettaglioTesto?.immagine" class="card-img-top img-fluid" style="max-width: 70%; max-height: 70%;" alt="{{shared?.dettaglioTesto?.immagine}}">
                </div>
              </div> -->
              <div class="row">
                <div class="col">
                  <h5 class="card-title">{{shared?.dettaglioTesto?.title.rendered}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col font-italic">
                  Pubblicato in origine su : <a [href]="shared?.dettaglioTesto?.link" target="_blank" >{{shared?.dettaglioTesto?.link}}</a>
                </div>
              </div>
             <div class="row mt-3">
               <div class="col">
                <p class="card-text text-justify" [innerHTML]="shared?.dettaglioTesto?.content.rendered | sanitizeHtml"></p>
               </div>
             </div>
            </div>
            <!-- <div class="card-action text-right">
              <a href="#" class="btn btn-primary mb-2 mr-2">Ascolta</a>
            </div> -->
            
          </div>            
    </div>
</div>