import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MediaType } from '../enums/mediatype.enum';
import { CanonicalService } from '../services/canonical.service';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-dettagli',
  templateUrl: './dettagli.component.html',
  styleUrls: ['./dettagli.component.scss']
})
export class DettagliComponent implements OnInit {

  urlVideoInvidious:string = "";

  constructor(
    public shared:SharedService,
    private sanitizer: DomSanitizer,
    private route:Router,
    private metaTag:Meta,
    private canonical:CanonicalService,
    private datePipe:DatePipe,
    private titleMeta:Title,
  ) { }

  ngOnInit() {

    this.metaTag.removeTag('name="description"');
    this.metaTag.removeTag('name="date"');
    this.metaTag.removeTag('name="authors"');
    this.metaTag.removeTag('name="keywords"');
    this.metaTag.removeTag('name="robots"');
     this.metaTag.addTags([
       {name : 'description', content : `Home Page Del Web e Dintorni - MaiStatoCosiFacile`},
       {name: 'date', content : `${this.formattaData(new Date())}`},
       {name : 'authors', content : 'Federico Rinaldi & Sandra Catalano'},
       {name : 'keywords', content : 'Informatica, Sviluppo web, java, angular, scuola, DAD, Google Meet, Google Learning, Coronavirus, Covid, Covid19, Scuola'},
       {name : 'robots', content : 'index, follow'}
     ]);
     this.titleMeta.setTitle(`Del Web e Dintorni MaiStatoCosiFacile - Dettaglio Contenuti`);
     this.canonical.createCanonicalUrl(`/dettaglio`);

     this.urlVideoInvidious = `{{"techOrder":["youtube"], "sources": [{ "type": "video/youtube", "src": "https://i.devol.it/watch?v=${this.shared.dettaglioVid.videoId}" }]}}`;

  }

  formattaData(dataPost:Date) : string | null{
    return this.datePipe.transform(dataPost,'dd/MM/yyyy','it-IT');
  }

  naviga(dove:string){
    if(dove === 'H'){
      this.shared.resetAllComponents();
      this.shared.videoPodcast = true;
      this.route.navigate(['']);
    }else if(dove === 'LP'){
      this.shared.resetAllComponents();
      this.shared.tipoListaShow = MediaType.PODCAST;
      this.shared.allPodcast = true;
      this.route.navigate(['recenti']);
    }else if(dove === 'LV'){
      this.shared.resetAllComponents();
      this.shared.tipoListaShow = MediaType.YOUTUBE;
      this.shared.allVideos = true;
      this.route.navigate(['recenti']);
    }else if(dove === 'LT'){
      this.shared.resetAllComponents();
      this.shared.tipoListaShow = MediaType.TESTI;
        this.shared.allTesti = true;
        this.route.navigate(['recenti']);
    }
  }

  sanitizeYoutubeUrl(videoid:string){
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoid}`);
  }

  sanitizeInvidiousUrl(videoid:string){
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://piped.kavin.rocks/watch?v=${videoid}`);
  }

}
