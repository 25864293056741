import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { MediaType } from 'src/app/enums/mediatype.enum';
import { WPblogModel } from 'src/app/models/wp-blog.model';
import { CanonicalService } from 'src/app/services/canonical.service';
import { SharedService } from 'src/app/services/shared.service';
import { TestiService } from 'src/app/services/testi.service';

@Component({
  selector: 'app-articolo',
  templateUrl: './articolo.component.html',
  styleUrls: ['./articolo.component.scss']
})
export class ArticoloComponent implements OnInit {

  constructor(
    private metaTag:Meta,
    private actRoute:ActivatedRoute,
    public shared:SharedService,
    private canonical:CanonicalService,
    private datePipe:DatePipe,
    private titleMeta:Title,
    private testi:TestiService,
    private route:Router
  ) { }

  ngOnInit() {
    this.shared.loadAndHideSpinner();
    const idArticolo:string = this.actRoute.snapshot.params.id.toString();
    if(this.shared.dettaglioTesto === null){
      this.shared.resetAllComponents();
      this.shared.dettaglio = true;
      this.getAllTestiFromSandra(idArticolo);
    }else{
      this.caricaMetadati();
    }

  }
  
  caricaMetadati(){
    this.metaTag.removeTag('name="description"');
    this.metaTag.removeTag('name="date"');
    this.metaTag.removeTag('name="authors"');
    this.metaTag.removeTag('name="keywords"');
    this.metaTag.removeTag('name="robots"');
     this.metaTag.addTags([
       {name : 'description', content : `${this.shared.dettaglioTesto.title.rendered}`},
       {name: 'date', content : `${this.formattaData(this.shared.dettaglioTesto.date)}`},
       {name : 'authors', content : 'Federico Rinaldi & Sandra Catalano'},
       {name : 'keywords', content : 'Informatica, Sviluppo web, java, angular, scuola, DAD, Google Meet, Google Learning, Coronavirus, Covid, Covid19, Scuola'},
       {name : 'robots', content : 'index, follow'}
     ]);
     this.titleMeta.setTitle(`Del Web e Dintorni MaiStatoCosiFacile - ${this.shared.dettaglioTesto.title.rendered.replace('_',' ')}`);
     this.canonical.createCanonicalUrl(`/articolo/${this.shared.dettaglioTesto.id}/${this.shared.dettaglioTesto.title.rendered.replace(' ','_')}`);
  }

  formattaData(dataPost:Date) : string | null{
    return this.datePipe.transform(dataPost,'dd/MM/yyyy','it-IT');
  }


  private getAllTestiFromSandra(idPost:string){
    const postSandra:Observable<WPblogModel[]> = this.testi.getAllTesti();
    const postFede:Observable<any> = this.testi.getAllTestiFede();
    forkJoin(postSandra,postFede).subscribe(result=>{
      const listaFede:WPblogModel[] = this.shared.createBlogFromGhostPublishing(result[1]);
      this.shared.testi = [...result[0],...listaFede];
      this.shared.testiOriginale = [...result[0],...listaFede];
      this.shared.dettaglioTesto = this.shared.testi.find(art => art.id.toString() === idPost);
      this.titleMeta.setTitle(`Del Web e Dintorni MaiStatoCosiFacile - ${this.shared.dettaglioTesto.title.rendered.replace('_',' ')}`);
      this.caricaMetadati();
    });
  }


  naviga(dove:string){
    if(dove === 'H'){
      this.shared.resetAllComponents();
      this.shared.videoPodcast = true;
      this.route.navigate(['recenti']);
    }else if(dove === 'LP'){
      this.shared.resetAllComponents();
      this.shared.tipoListaShow = MediaType.PODCAST;
      this.shared.allPodcast = true;
      this.route.navigate(['recenti']);
    }else if(dove === 'LV'){
      this.shared.resetAllComponents();
      this.shared.tipoListaShow = MediaType.YOUTUBE;
      this.shared.allVideos = true;
      this.route.navigate(['recenti']);
    }else if(dove === 'LT'){
      this.shared.resetAllComponents();
      this.shared.tipoListaShow = MediaType.TESTI;
        this.shared.allTesti = true;
        this.route.navigate(['recenti']);
    }
  }
}
